export const bootstrap = {
  default: '#344054', // bionic-900
  primary: '#99B898',
  secondary: '#6c757d',
  info: '#7EBCE6',
  success: '#99B898',
  danger: '#E84A5F',
  warning: '#ffc107',
  dark: '#344054B',
  background: '#fcfcfd' // bionic-white
}

export const branding = {
  'fluencyLighterblue': '#74ADD2',
  'fluencyDarkerblue': '#394B63',
  'fluencyLightergray': '#EEF0F3', // bionic-200
  'fluencyPurple': '#B49CBB',
  'fluencyGreen': '#99B898',
  'fluencyGreenFaded': 'rgba(153, 184, 152, 0.5)',
  'fluencyPink': '#FF847C',
  'fluencyRed': '#E84A5F',
  'fluencyGray': '#475467', // bionic-800
  'fluencyLightgray': '#98a2b3', // bionic-600
  'fluencyBlue': '#6BA7FF',
  'fluencyPaleBlue': '#A4D4E6',
  'fluencyYellow': '#FECEA8',
}
// export { bootstrap, branding }

export default {
  bootstrap,
  branding
}
